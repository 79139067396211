<template>

	<!-- <div v-if="state.device=='xbox' || state.device=='nintendo'" class="q-gutter-md q-pl-md q-pt-md" :style="'color:'+state.color">
		คุณสามารถเปลี่ยนราคาเป็นเรท VISA หรือ MC และใส่ค่าธรรมเนียมธนาคาร ได้ที่ปุ่ม <q-icon class="no-margin" size="sm" name="credit_card" /> ด้านบน
	</div> -->
	<!------------------- Search ------------------------>
	<!-- <q-img src="images/banner/banner.jpg" ></q-img> -->
	<div class="col-xs-12 col-sm-6 text-bold">
		สามารถร่วมสมทบทุน server และให้กำลังใจได้เลยนะครับ 
		<q-btn round icon="qr_code_2" @click="donate=true" :color="state.color" dense outline />
		<!-- <a href="https://www.facebook.com/numcreator/posts/pfbid0veZ8fWXAhuNeWYKtgkJtjVNdXsoVMLSgNAkkDcmUzhUTEWK8DhLCijL3YWHWpijEl" target="_blank">Click</a> -->
	</div>

	<!-- <div v-if="state.device=='playstation'" class="col-xs-12 col-sm-6 text-red text-bold">
		ขณะนี้ระบบการเช็คราคาของ Playstation ไม่สามารถทำงานได้ ราคาที่แสดงจึงไม่ใช่ราคาปัจจุบันนะครับ
	</div> -->
	<div class="q-pa-md q-gutter-sm row">
		<q-input v-if="state.device!='home'" v-model="search" label="Search" @keydown.enter="searchData()" clearable dense class="col-xs-12 col-sm-5 q-pr-sm">
			<template v-slot:after>
				<q-btn  round :color="state.color" icon="search" @click="searchData()"/>
			</template>
		</q-input>
		<div v-if="state.device=='xbox'" class="col-xs-12 col-sm-6">
			<q-scroll-area style="height: 75px;">
				<div class="q-py-xs row no-wrap q-col-gutter-md">
					<div style="width:100px;cursor: pointer;">
						<q-card><q-img src="/images/hot/diablo.png" class="rounded-borders" @click="searchData('diablo iv')"></q-img></q-card>
					</div>
					<div style="width:100px;cursor: pointer;">
						<q-card><q-img src="/images/hot/apex.png" class="rounded-borders" @click="searchData('apex legend')"></q-img></q-card>
					</div>
					<div style="width:100px;cursor: pointer;">
						<q-card><q-img src="/images/hot/fifa.png" class="rounded-borders" @click="searchData('fifa point')"></q-img></q-card>
					</div>
					<div style="width:100px;cursor: pointer;">
						<q-card><q-img src="/images/hot/callofduty.png" class="rounded-borders" @click="searchData('0 call of duty')"></q-img></q-card>
					</div>
					<div style="width:100px;cursor: pointer;">
						<q-card><q-img src="/images/hot/overwatch.png" class="rounded-borders" @click="searchData('overwatch')"></q-img></q-card>
					</div>
				</div>
			</q-scroll-area>
		</div>
		<div v-if="state.device=='home'" class="col-xs-8 col-sm-12">
			<div class="q-py-xs row q-col-gutter-sm">
				<div>
					<q-btn flat dense href="/appletv">
						<q-avatar rounded><img src="/images/icon_appletv.png"></q-avatar>
					</q-btn>
				</div>
				<div>
					<q-btn flat dense href="/playstation">
						<q-avatar rounded><img src="/images/icon_playstation.png"></q-avatar>
					</q-btn>
				</div>
				<div>
					<q-btn flat dense href="/xbox">
						<q-avatar rounded><img src="/images/icon_xbox.png"></q-avatar>
					</q-btn>
				</div>
				<div>
					<q-btn flat dense href="/nintendo">
						<q-avatar rounded><img src="/images/icon_nintendo.png"></q-avatar>
					</q-btn>
				</div>
				<div>
					<q-btn flat dense href="/steam">
						<q-avatar rounded><img src="/images/icon_steam.png"></q-avatar>
					</q-btn>
				</div>
				<div>
					<q-btn flat dense href="/exchange">
						<q-avatar rounded><img src="/images/icon_exchange.png"></q-avatar>
					</q-btn>
				</div>
			</div>
		</div>
		<!-- <div class="q-pl-lg col-6"><q-img src="images/banner/banner.jpg" ></q-img></div> -->
	</div>

	<!------------------- Hot ------------------------>
	<!-- <div>
		<q-chip v-if="state.showHot"  class="q-pa-md" :ripple="false">
			<q-avatar icon="local_fire_department" :color="state.color" text-color="white" />
			HOT
		</q-chip>
		<div v-if="state.device=='home'" class="q-pa-md row q-col-gutter-md">
			<ViewCard :formData="formData" :key="refresh" />
		</div>
		<div v-else v-show="state.showHot && state.device!='home'" class="column" :style="'height:'+state.cardHight">
			<q-scroll-area class="col-12 q-pa-sm">
				<div class="q-pa-md row no-wrap q-col-gutter-md">
					<ViewCard :formData="formData" />
				</div>
			</q-scroll-area>
		</div>
		<q-separator v-if="state.showHot" class="q-mb-md" />
	</div> -->

	<!------------------- Show Card ------------------------>
	<div v-for="item in cards" :key="item">
		<div class="row">
			<q-chip :ripple="false">
				<q-avatar v-if="item.icon!='TH'" :icon="item.icon" :color="state.color" text-color="white" />
				<q-avatar v-else :color="state.color" text-color="white" size="sm" class="text-weight-medium" >{{item.icon}}</q-avatar>
				{{item.label}}
			</q-chip>
			<q-btn flat rounded size="sm" label="view all" :to="'/'+state.device+item.link" />
		</div>
		<div class="column" :style="'height:'+state.cardHight">
			<q-scroll-area class="col-12 q-pa-sm">
				<div class="q-pa-md row no-wrap q-col-gutter-md">
					<ViewCard :formData="item.form" />
				</div>
			</q-scroll-area>
		</div>
	
		<q-separator class="q-mb-md" />

	</div>

	<!------------------- Homw New ------------------------>
	<div v-if="state.device=='home' && !state.showHot">
		<!-- <q-chip class="q-pa-md" :ripple="false">
			<q-avatar icon="local_fire_department" :color="state.color" text-color="white" />
			New Release
		</q-chip> -->
		<div class="q-pa-md row q-col-gutter-md">
			<ViewCard :formData="formNew" :key="refresh" />
		</div>
	</div>

	<q-dialog v-model="donate">
		<q-card style="width:400px;">
			<q-img src="/images/pp.jpg"></q-img>
		</q-card>
	</q-dialog>

</template>

<script setup>
	import { ref } from 'vue'
	import ViewCard from '../components/ViewCard.vue';
	import { useState } from '../stores/webStore';
	import { useRoute,useRouter } from 'vue-router';
	const route = useRoute();
    const router = useRouter();
	const state = useState();
	state.setRoute(route.name);
	let search = ref('');
	let cards = ref([]);
	let store = state.store;
	let currency = state.currency;
	let refresh = ref(0);
	let donate = ref(false);
	// let formData = ref({
	// 	productKind: 'hot',
	// 	order: 'hotdate',
	// 	store: store,
	// 	currency: currency,
	// 	limit: 20,
	// 	showARS: state.loc.showARS,
	// 	lib: state.loc.lib
	// });
	let formDeal = {
		price: 'deal',
		productKind: 'all',
		order: 'cdate desc',
		store: store,
		currency: currency,
		limit: 20,
		showARS: state.loc.showARS,
		lib: state.loc.lib
	};
	let formAdd = {
		productKind: 'all',
		order: 'ddate desc',
		store: store,
		currency: currency,
		limit: 20,
		showARS: state.loc.showARS,
		lib: state.loc.lib
	};
	let formPo = {
		productKind: 'po',
		order: 'releaseDate',
		store: store,
		currency: currency,
		limit: 20,
		showARS: state.loc.showARS,
		lib: state.loc.lib
	};
	let formNew = {
		productKind: 'new',
		order: 'releaseDate desc',
		store: store,
		currency: currency,
		limit: 20,
		showARS: state.loc.showARS,
		lib: state.loc.lib
	};

	if(state.device=='appletv'){
		cards.value = [
			{icon:'local_offer',color:'blue',label:'Deals',form:formDeal,link:'/deal'},
			{icon:'save_alt',color:'blue',label:'Recently Add',form:formAdd,link:'/recent'},
			{icon:'fiber_new',color:'blue',label:'New Release',form:formNew,link:'/newrelease'},
		];
	} else if(state.device=='home'){
		cards.value = [
		];
	} else {
		cards.value = [
			{icon:'local_offer',color:'blue',label:'Deals',form:formDeal,link:'/deal'},
			{icon:'save_alt',color:'blue',label:'Recently Add',form:formAdd,link:'/recent'},
			{icon:'schedule',color:'blue',label:'Pre-Order',form:formPo,link:'/preorder'},
			{icon:'fiber_new',color:'blue',label:'New Release',form:formNew,link:'/newrelease'},
		];
	}

	state.title = state.device!='home'?state.device.toUpperCase()+" Thailand Price Chart":'Price';
	state.desc = "Check Price of content on "+state.device.toUpperCase()+" Thailand";
	state.image = "/images/logo_"+state.device+".png"

	// let formPlus = {
	// 	productKind: 'plus',
	// 	order: 'releaseDate desc',
	// 	store: store,
	// 	currency: currency,
	// 	limit: 20,
	// };
	// let formThai = {
	// 	productKind: 'sthai',
	// 	order: 'releaseDate desc',
	// 	store: store,
	// 	currency: currency,
	// 	limit: 20,
	// };
	// if(store == 'th'){
	// 	cards.value.push({icon:'add',color:'yellow-8',label:'Playstation Plus',form:formPlus,link:'/plus'});
	// 	cards.value.push({icon:'TH',color:'blue',label:'Thai Subtitle',form:formThai,link:'/subthai'});
	// }

	function searchData(val){
		if(val!=undefined){
			router.push({name:'searchParam.'+state.device,params:{txt:val}});
		}
		else if(search.value && search.value != ''){
			router.push({name:'searchParam.'+state.device,params:{txt: search.value}});
		}else if(state.device!='home'){
			router.push('/'+state.device+'/search');
		}
		
	}

</script>